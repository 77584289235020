/* InfoPopup.css */
.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #eecaca;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #f8d7da;
    border-radius: 3px;
    border: 1px solid #59141b;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #59141b;
  }