.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    border-radius: 5px;
    width: 500px !important;
    max-width: 100%;
    z-index: 1001;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.modal-title {
    margin: 0;
}

.close {
    background: none;
    border: none;
    font-size: 1.5rem;
}

.modal-body {
    margin-top: 10px;
    margin-bottom: 10px;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}