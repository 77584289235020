//$primary: #870000; // Nowy podstawowy kolor, możesz użyć dowolnego koloru

// Importowanie Bootstrapa
@import "../../../node_modules/bootstrap/scss/bootstrap";

:root {
    --bs-blue: #0074c2;
    --bs-indigo: #727cf5;
    --bs-purple: #6b5eae;
    --bs-pink: #ff679b;
    --bs-red: #db0028;
    --bs-orange: #fd7e14;
    --bs-yellow: #F6C343;
    --bs-green: #00D97E;
    --bs-teal: #02a8b5;
    --bs-cyan: #39afd1;
    --bs-black: #12263F;
    --bs-gray: #95AAC9;
    --bs-gray-dark: #3B506C;
    --bs-gray-100: #F9FBFD;
    --bs-gray-200: #EDF2F9;
    --bs-gray-300: #E3EBF6;
    --bs-gray-400: #D2DDEC;
    --bs-gray-500: #B1C2D9;
    --bs-gray-600: #95AAC9;
    --bs-gray-700: #6E84A3;
    --bs-gray-800: #3B506C;
    --bs-gray-900: #283E59;
    --bs-primary: #0074c2;
    --bs-secondary: #122963;
    --bs-success: #00D97E;
    --bs-info: #39afd1;
    --bs-warning: #F6C343;
    --bs-danger: #db0028;
    --bs-light: #EDF2F9;
    --bs-dark: #12263F;
    --bs-white: #FFFFFF;
    --bs-primary-rgb: 0, 116, 194;
    --bs-secondary-rgb: 18, 41, 99;
    --bs-success-rgb: 0, 217, 126;
    --bs-info-rgb: 57, 175, 209;
    --bs-warning-rgb: 246, 195, 67;
    --bs-danger-rgb: 219, 0, 40;
    --bs-light-rgb: 237, 242, 249;
    --bs-dark-rgb: 18, 38, 63;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 18, 38, 63;
    --bs-body-color-rgb: 18, 38, 63;
    --bs-body-bg-rgb: 249, 251, 253;
    --bs-font-sans-serif: "Commissioner", sans-serif;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: .9375rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #12263F;
    --bs-body-bg: #F9FBFD;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #E3EBF6;
    --bs-border-color-translucent: rgba(18, 38, 63, .175);
    --bs-border-radius: .375rem;
    --bs-border-radius-sm: .25rem;
    --bs-border-radius-lg: .5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #0074c2;
    --bs-link-hover-color: #004676;
    --bs-code-color: var(--bs-blue);
    --bs-highlight-bg: #fdf3d9
}

.img-cover
{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-20%,-50%);
  max-width: 100%;
  max-height: 100%;
  display: block!important;
} 

.relative {
  position: relative;
}

.bg-secondary {
    background-color: #122963 !important;
}

.display-4 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.1;
}

.px-xl-5 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(18,38,63,0)
}

.mb-auto {
    margin-bottom: auto!important;
}

.fade {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    /* domyślnie niewidoczny */
}

.fade.show {
    opacity: 1;
    /* pełna widoczność */
}

.btn {
    --bs-btn-padding-x: .75rem;
    --bs-btn-padding-y: .5rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: .9375rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: #12263F;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: .375rem;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(18, 38, 63, .075);
    --bs-btn-disabled-opacity: .65;
    --bs-btn-focus-box-shadow: 0 0 0 .15rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.btn-primary {
    --bs-btn-color: #FFFFFF;
    --bs-btn-bg: #0074c2;
    --bs-btn-border-color: #0074c2;
    --bs-btn-hover-color: #FFFFFF;
    --bs-btn-hover-bg: #0063a5;
    --bs-btn-hover-border-color: #005d9b;
    --bs-btn-focus-shadow-rgb: 38, 137, 203;
    --bs-btn-active-color: #FFFFFF;
    --bs-btn-active-bg: #005d9b;
    --bs-btn-active-border-color: #005792;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(18, 38, 63, .125);
    --bs-btn-disabled-color: #FFFFFF;
    --bs-btn-disabled-bg: #0074c2;
    --bs-btn-disabled-border-color: #0074c2;
}

.btn-outline-primary {
    --bs-btn-color: #0074c2;
    --bs-btn-border-color: #0074c2;
    --bs-btn-hover-color: #FFFFFF;
    --bs-btn-hover-bg: #0074c2;
    --bs-btn-hover-border-color: #0074c2;
    --bs-btn-focus-shadow-rgb: 0, 116, 194;
    --bs-btn-active-color: #FFFFFF;
    --bs-btn-active-bg: #0074c2;
    --bs-btn-active-border-color: #0074c2;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(18, 38, 63, .125);
    --bs-btn-disabled-color: #0074c2;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0074c2;
    --bs-gradient: none;
}

.w-100 {
    width: 100% !important;
}


button, [type=button], [type=reset], [type=submit] {
    -webkit-appearance: button;
}

button, select {
    text-transform: none;
}

input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}


@font-face {
    font-family: Commissioner;
    src: url(Commissioner-Regular-tQ5td2aX.eot);
    src: url(Commissioner-Regular-tQ5td2aX.eot?#iefix) format("embedded-opentype"),url(Commissioner-Regular-SGF9IqZg.woff) format("woff"),url(Commissioner-Regular--wScZOgu.ttf) format("truetype");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Commissioner;
    src: url(Commissioner-Medium-u5Oaazgb.eot);
    src: url(Commissioner-Medium-u5Oaazgb.eot?#iefix) format("embedded-opentype"),url(Commissioner-Medium-chnl0BOS.woff) format("woff"),url(Commissioner-Medium-uKJ_efEM.ttf) format("truetype");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: Commissioner;
    src: url(Commissioner-SemiBold-2dkvZIa9.eot);
    src: url(Commissioner-SemiBold-2dkvZIa9.eot?#iefix) format("embedded-opentype"),url(Commissioner-SemiBold-KFeRZZXT.woff) format("woff"),url(Commissioner-SemiBold-iGjdT6zw.ttf) format("truetype");
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: Commissioner;
    src: url(Commissioner-Bold-gLKQ5ekY.eot);
    src: url(Commissioner-Bold-gLKQ5ekY.eot?#iefix) format("embedded-opentype"),url(Commissioner-Bold-dt0vkizY.woff) format("woff"),url(Commissioner-Bold--2xNTWbH.ttf) format("truetype");
    font-weight: 700;
    font-style: normal
}

/* CSS przykładowy dla menu bocznego, które dokuje się na ekranach większych niż 992px */
@media (min-width: 992px) {
    .offcanvas {
      transform: none; /* Unieważnij transformację, aby menu było widoczne */
      visibility: visible; /* Upewnij się, że menu jest widoczne */
    }
  
    /* Dodatkowe stylowanie, aby upewnić się, że zawartość strony nie jest nakrywana przez menu */
    .body-content {
      margin-left: 250px; /* Zakładając, że szerokość menu wynosi 250px */
    }
  }

/* Stylowanie dla kontenera głównego, który obejmuje sidebar i treść główną */
.app-container {
    display: flex;
    flex-direction: row;
  }
  
  /* Stylowanie dla sidebara */
  .sidebar {
    width: 250px; /* Przykładowa stała szerokość dla sidebara */
    /* inne style dla sidebara */
  }
  
  /* Stylowanie dla treści głównej */
  .content {
    flex-grow: 1; /* Treść zajmuje dostępną przestrzeń */
    padding: 20px; /* Odstęp od krawędzi kontenera */
  }

:root
{
    --bs-font-sans-serif: "Commissioner", sans-serif;
    --bs-body-font-size: .9375rem;

}

.floating-button {
    position: fixed; /* Pozycjonowanie względem okna przeglądarki */
    top: 20px; /* Odległość od dolnej krawędzi */
    right: 20px; /* Odległość od prawej krawędzi */
    z-index: 999; /* Upewnia się, że przycisk będzie nad innymi elementami */
}

.offcanvas{
    --bs-offcanvas-width: 290px;
    --bs-offcanvas-bg: #122963;
}

.card {
    --bs-card-spacer-y: 1.5rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: .5rem;
    --bs-card-border-width: var(--bs-border-width);
    --bs-card-border-color: var(--bs-gray-200);
    --bs-card-border-radius: var(--bs-border-radius-lg);
    --bs-card-box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
    --bs-card-inner-border-radius: calc(var(--bs-card-border-radius) - var(--bs-card-border-width));
    --bs-card-cap-padding-y: 1rem;
    --bs-card-cap-padding-x: var(--bs-card-spacer-x);
    --bs-card-cap-bg: transparent;
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: var(--bs-white);
    --bs-card-img-overlay-padding: 1.5rem;
    --bs-card-group-margin: .75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius)
}