/* StatusBadge.css */

/* Ogólny styl dla wszystkich statusów */
.status-badge {
    color: white;
    font-size: small;
    padding: 0.1rem 1rem;
    border-radius: 5px;
    display: inline-block;
}

.status-badge.green {
    background-color: #8CC63F;
    color: black !important;
}

.status-badge.light-green {
    background-color: #d1e8b2;
    color: black !important;
}

.status-badge.green2 {
    background-color: #00d97e;
    color: black !important;
}

.status-badge.light-grey {
    background-color: #e6e6e6;
    color: black !important;
}

.status-badge.yellow {
    background-color: #FCEE21;
    color: black !important;
}

.status-badge.orange {
    background-color: #F7931E;
    color: black !important;
}

.status-badge.red {
    background-color: #DB0028;
    color: white !important;
}

.status-badge.maroon {
    background-color: #8B1F41;
    color: white !important;
}   

.status-badge.blue {
    background-color: #7fcdee;
    color: black !important;
}

.status-badge.violet {
    background-color: #93278F;
    color: white !important;
}

.status-badge.light-violet {
    background-color: #d4a9d2;
    color: black !important;
}

.status-badge.black {
    background-color: #000000;
    color: black !important;
}
